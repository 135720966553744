$(function () {
  $('[data-nav-trigger],#sp-access').on('click', function () {
    $(this).toggleClass('active');
    $('.p-gnav-overlay').toggleClass('visible');
    $('[data-nav]').toggleClass('active');
    $('#menuLabel').toggleClass('active');
    var label = $('#menuLabel').text();
    if (label === 'Menu') {
      $('#menuLabel').text('Close');
      $('#menuIcon').text('close');
    } else {
      $('#menuLabel').text('Menu');
      $('#menuIcon').text('drag_handle');
    }
    return false;
  });

  

  
  

  
});
